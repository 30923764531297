<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col>
          <b-card>
            <sales-chart title="Vendite" revenue />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-card>
            <sales-chart title="Markup" markup />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import SalesChart from "@/components/SalesChart.vue";

export default {
  name: "Sales",
  components: { SalesChart },
  methods: {
    sendData: function() {
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}user`, this.form)
        .then(res => {
          this.registered = res.data.success;
        })
        .catch(err => {
          this.error = err;
        });
    },
    setViewMonth: function() {
      this.chartView = "month";
      this.setChart();
    },
    setViewYear: function() {
      this.chartView = "year";
      this.setChart();
    }
  }
};
</script>
