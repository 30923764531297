<template>
  <div>
    <b-row align-h="between">
      <b-col
        ><h2>{{ title }}</h2></b-col
      >
      <b-col class="text-right">
        <b-button-group class="mr-3">
          <b-button
            :variant="chartType === 'line' ? 'primary' : 'secondary'"
            @click="chartType = 'line'"
          >
            <font-awesome-icon icon="chart-line"
          /></b-button>
          <b-button
            :variant="chartType === 'bar' ? 'primary' : 'secondary'"
            @click="chartType = 'bar'"
          >
            <font-awesome-icon icon="chart-bar"
          /></b-button>
        </b-button-group>
        <b-button-group>
          <b-button
            :variant="chartView === 'day' ? 'primary' : 'secondary'"
            @click="setViewDay"
            >Day</b-button
          >
          <b-button
            :variant="chartView === 'month' ? 'primary' : 'secondary'"
            @click="setViewMonth"
            >Month</b-button
          >
          <b-button
            :variant="chartView === 'year' ? 'primary' : 'secondary'"
            @click="setViewYear"
            >Year</b-button
          >
        </b-button-group></b-col
      >
    </b-row>
    <line-chart
      v-if="chartType === 'line'"
      :data="revenue ? chartData : chartDataMarkup"
      :options="revenue ? options : optionsMarkup"
      :height="300"
    />
    <bar-chart
      v-if="chartType === 'bar'"
      :data="revenue ? chartData : chartDataMarkup"
      :options="revenue ? options : optionsMarkup"
      :height="300"
    />
  </div>
</template>

<script>
import LineChart from "../components/LineChart.vue";
import BarChart from "@/components/BarChart.vue";

export default {
  components: {
    BarChart,
    LineChart
  },
  name: "SalesChart",
  props: {
    title: {
      default: "",
      type: String
    },
    revenue: {
      default: false,
      type: Boolean
    },
    markup: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      chartType: "line",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Revenue",
            borderColor: "#00A5E0",
            data: [],
            backgroundColor: "#00A5E044",
            tension: 0
          },
          {
            label: "Average Revenue",
            borderColor: "#00A5E0",
            data: [],
            tension: 0,
            borderDash: [10, 15]
          },
          {
            label: "Earned",
            borderColor: "#58D68D",
            backgroundColor: "#58D68D44",
            data: [],
            tension: 0
          },
          {
            label: "Average Earned",
            borderColor: "#58D68D",
            data: [],
            tension: 0,
            borderDash: [10, 15]
          }
        ]
      },
      options: {
        response: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return value.toLocaleString(this.lang, {
                    style: "currency",
                    currency: "EUR",
                    currencyDisplay: "symbol"
                  });
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return tooltipItem.yLabel.toLocaleString(this.lang, {
                style: "currency",
                currency: "EUR",
                currencyDisplay: "symbol"
              });
            }
          }
        }
      },
      chartDataMarkup: {
        labels: [],
        datasets: [
          {
            label: "Markup",
            borderColor: "#22d904",
            backgroundColor: "#22d90444",
            data: [],
            tension: 0
          },
          {
            label: "Average Markup",
            borderColor: "#22d904",
            data: [],
            tension: 0,
            borderDash: [10, 15]
          }
        ]
      },
      optionsMarkup: {
        response: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return value.toLocaleString(this.lang, {
                    style: "percent"
                  });
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return tooltipItem.yLabel.toLocaleString(this.lang, {
                style: "percent"
              });
            }
          }
        }
      },
      fromDate: null,
      thruDate: null,
      chartView: "day"
    };
  },
  beforeMount() {
    this.lang = navigator.language || navigator.userLanguage;
    this.setChart();
  },
  methods: {
    setChart: function() {
      this.step = 1;
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}chart/sales/${this.chartView}`)
        .then(res => {
          this.setDataChart(res.data);
        })
        .catch(err => {
          this.error = err;
        });
    },
    setDataChart: function(data) {
      if (this.revenue) {
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        this.chartData.datasets[1].data = [];
        this.chartData.datasets[2].data = [];
        this.chartData.datasets[3].data = [];
      }
      if (this.markup) {
        this.chartDataMarkup.labels = [];
        this.chartDataMarkup.datasets[0].data = [];
        this.chartDataMarkup.datasets[1].data = [];
      }
      let that = this;
      let averageMarkup = 0;
      let averageRevenue = 0;
      let averageEarned = 0;
      data.forEach(function(element) {
        averageMarkup += element.markup * 10;
        averageRevenue += element.value;
        averageEarned += element.earnedValue;
      });
      averageMarkup = averageMarkup / data.length;
      averageRevenue = averageRevenue / data.length;
      averageEarned = averageEarned / data.length;
      data.forEach(function(element) {
        let date = new Date(element.date.replace(" ", "T"));
        let label = that.$t("monthName")[date.getMonth()];
        if (that.chartView === "day") label = date.getDate() + " " + label;
        else if (that.chartView === "year") label = date.getFullYear();
        if (that.revenue) {
          that.chartData.labels.push(label);
          that.chartData.datasets[0].data.push(element.value);
          that.chartData.datasets[1].data.push(averageRevenue);
          that.chartData.datasets[2].data.push(element.earnedValue);
          that.chartData.datasets[3].data.push(averageEarned);
        }
        if (that.markup) {
          that.chartDataMarkup.labels.push(label);
          that.chartDataMarkup.datasets[0].data.push(element.markup * 10);
          that.chartDataMarkup.datasets[1].data.push(averageMarkup);
        }
      });
    },
    setViewDay: function() {
      this.chartView = "day";
      this.setChart();
    },
    setViewMonth: function() {
      this.chartView = "month";
      this.setChart();
    },
    setViewYear: function() {
      this.chartView = "year";
      this.setChart();
    }
  }
};
</script>
