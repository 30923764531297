<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["data", "options"],
  mounted() {
    this.renderLineChart();
  },
  computed: {
    datasets: function() {
      let result = [];
      this.data.datasets.forEach(function(element) {
        result.push(element.data);
      });
      return result;
    }
  },
  methods: {
    renderLineChart: function() {
      this.renderChart(this.data, this.options);
    }
  },
  watch: {
    datasets: function() {
      this.renderLineChart();
    }
  }
};
</script>
